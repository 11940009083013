<template>
  <div class="top_fixed">
    <header class="menu_logout">
      <div class="wrap flex">
        <button class="sp_menu_btn"><span></span><span></span><span></span>Open Menu</button>
        <!--スマホメニュー開閉ボタン オープン時はnavにclass open-->
        <h1 id="h_logo">
          <a href="/">
            <div class="logo">複眼経済塾</div>
          </a>
        </h1>
        <ul class="h_nav flex">
          <li class="member_info">
            <div v-html="header_lead_text"></div>
          </li>
          <li class="pc_menu">
            <router-link to="/faq/" class="link">よくあるご質問</router-link>
          </li>
          <li class="pc_menu">
            <router-link to="/contacts/">お問い合わせ</router-link>
          </li>
          <!--<li class="pc_menu"><a href="javascript:void(0)" class="btn search"><span>サイト内検索</span></a></li>
          <li class="pc_menu"><a href="" class="btn my_timeline"><span>自分のタイムライン</span></a></li>
          <li class="sp_menu"><a href="" class="alert on"><img src="../common/images/icon/bell.svg" alt="通知"></a></li>-->
          <li class="pc_menu"><router-link to="/calendar" class="calendar flex"><img src="/common/images/icon/header_calendar.svg" alt="カレンダー"><p>カレンダー</p></router-link></li>
          <li v-if="!isUserSignedIn()">
            <router-link :to="{ name: 'UsersSignIn'}" class="btn user link">
              <i class="user_icon"><img src="/common/images/icon/h_login.svg" alt=""></i><span></span>
            </router-link>
          </li>

          <li v-if="isUserSignedIn()">
            <router-link :to="{ name: 'UsersCustomerAccount'}" class="btn user login link">
              <i class="user_icon">
                <img v-bind:src="$route.meta.current_user.profile_image_url" v-if="$route.meta.current_user" alt="">
              </i><span></span>
            </router-link>
          </li>

        </ul>
      </div>
    </header>

    <nav class="menu_logout">
      <div id="dl-menu" class="dl-menuwrapper flex">
        <div class="btn_box flex">
          <button class="sp_menu_btn close"><span></span><span></span><span></span></button>
          <div class="search_btn"></div>
          <div class="calendar_sp flex">
            <router-link to="/calendar" class="calendar flex"><img src="/common/images/icon/header_calendar_wht.svg" alt="カレンダー"><p>カレンダー</p></router-link>
          </div>
        </div>
        <ul class="dl-menu flex">
          <li class="ttl">メニュー</li>
          <li class=""><a href="https://www.millioneyes.jp/#course">クラス概要</a></li>
          <li class=""><a href="https://www.millioneyes.jp/#teacher">講師陣</a></li>
          <li class=""><a href="https://www.millioneyes.jp/irmtg/">複眼IR MTG</a></li>
          <li class=""><router-link :to="{ name: 'SnsSpace', params: {nav_category: 'media', space_id: sns_media_id(), room_id: 0}}">メディア</router-link></li>
          <li class=""><a href="https://www.millioneyes.jp/instructor/">講演</a></li>
          <li class=""><a href="https://www.millioneyes.jp/corporate_training/">企業研修</a></li>
          <li class=""><a href="https://www.millioneyes.jp/company/">会社概要</a></li>
          <li class=""><router-link :to="{ name: 'ShoppingArticles'}">ショップ</router-link></li>
          <li class="ttl">その他</li>
          <li class="sp_menu"><router-link to="/faq/">よくあるご質問</router-link></li>
          <li class="sp_menu"><router-link to="/contacts/">お問い合わせ</router-link></li>
          <li class="ttl">ご利用に関するお問い合わせはこちら</li>
          <li class="tel_num">
            <a href="tel:0362808451" class="flex">
              <div class="icon"></div>
              <div class="cts">
                <p class="number">03-6280-8451</p>
                <p class="red bold">タップで自動的に電話を発信します</p>
              </div>
            </a>
          </li>
          <!--<li class="ttl">自分のタイムラインの確認と投稿はこちら</li>
          <li class="time_line">
            <a href="">自分のタイムライン</a>
          </li>-->
          <li class="ttl">会員の方はこちら</li>
          <li class="my_page">
            <a href="" class="flex">
              <div class="user_icon"><img src="/common/images/sample/preset_img01.jpg" alt=""></div>
              <p>複眼経済塾にログイン</p>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  components: {},
  data() {
    return {}
  },
  methods: {},
  mounted() {
    if (!window.matchMedia('(min-width: 768px)').matches) {
      window.$(".sp_menu_btn").on("click", function () {
        if (window.$(this).hasClass('close')) {
          window.$("body").removeClass('menu_open');
          window.$("nav").removeClass('open');
        } else {
          window.$("nav").addClass('open');
        }
      });
      window.$(".dl-menu li").on("click", function () {
        if (window.$(this).hasClass('open')) {
          window.$(".dl-menu li").removeClass('open');
        } else {
          if (window.$(this).hasClass('link')) {
            //window.$('.sp_menu_btn').trigger('click');
          } else {
            window.$(this).addClass('open');
          }
        }
      });
      window.$(document).click(function (event) {
        if (!window.$(event.target).closest('.mega_menu .menu_wrap').length) {
          window.$(".dl-menu li.open").removeClass('open');
        }
      });
    }
    window.$('a').click(function () {
      if (window.$(this).hasClass('link')) {
        //this.closeAllMenu()
        window.$('.sp_menu_btn').trigger('click');
      }
    });
  }
}
</script>
