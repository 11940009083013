<template>
  <div class="fee-failed-bar-frame">
    <div class="fee-failed-bar-content flex">
      <h4>注意</h4>
      <p v-if="!bank_fee">
        {{ moment(new Date(), 'YYYY年M月') }}の月謝の決済に失敗しました。考えられる原因は、カード期限切れ・限度額オーバー及びカードの停止などです。
        原因についてクレジットカード会社にご確認の上、ご対応をお願いいたします。
        なお、カード期限の到来についてはお使いのカード会社によっては自動継続できる場合とできない場合があります。あらかじめご了承ください。
        10日後前後に再決済をいたします。 その際に決済が成功した場合は、こちらのアラート画面は解消されます。
        現在参加申し込み済みのイベントについても {{ moment(new Date(), 'YYYY年M月') }}10日までに月謝のお支払いが行われない場合はキャンセル扱いとなり、参加することができなくなります。
        この件についてご不明な点がある場合は複眼経済塾事務局へお問い合わせください
      </p>
      <p v-else>
        {{ moment(new Date(), 'YYYY年M月') }}の月謝のお振込みが確認できておりません。お支払いをお願いいたします。<br>
        現在参加申し込み済みのイベントについても {{ moment(new Date(), 'YYYY年M月') }}10日までに月謝のお支払いが行われない場合はキャンセル扱いとなり、参加することができなくなります。<br>
        この件についてご不明な点がある場合は複眼経済塾事務局へ<router-link to="/contacts/">お問い合わせ</router-link>ください
      </p>
    </div>
  </div>
</template>

<script>
import Libraries from "@/components/Libraries.vue";

export default {
  mixins: [Libraries],
  props: {
    bank_fee: null
  }
}
</script>