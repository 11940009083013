<template>
  <div class="layout_menu">
    <Header/>
    <Flash v-if="$store.state.flash_message"/>
    <div id="router-view-frame">
      <router-view/>
      <Footer/>
    </div>
  </div>
</template>

<script>
import Header from '@/elements/confirmation/Header.vue'
import Footer from '@/elements/confirmation/Footer.vue'
import Flash from '@/elements/Flash.vue'

export default {
  components: {
    Footer, Header, Flash
  }
}
</script>
