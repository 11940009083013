var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('footer',[_c('div',{staticClass:"footer_nav"},[_c('ul',{staticClass:"f_nav flex"},[_vm._m(0),(false)?_c('li',[_c('a',{attrs:{"href":""}},[_vm._v("事務局blog")])]):_vm._e(),(false)?_c('li',[_c('a',{attrs:{"href":""}},[_vm._v("塾生クラス変更")])]):_vm._e(),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])]),_c('p',{attrs:{"id":"copyright"}},[_vm._v("© "+_vm._s(new Date().getFullYear())+" 複眼経済塾株式会社 All Rights Reserved.")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('a',{attrs:{"href":"/"}},[_vm._v("複眼経済塾 トップ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('a',{attrs:{"href":"https://www.millioneyes.jp/privacy-policy/"}},[_vm._v("個人情報保護方針")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('a',{attrs:{"href":"https://www.millioneyes.jp/transactions-law/"}},[_vm._v("「特定商取引に関する"),_c('br',{staticClass:"sp"}),_vm._v("法律」に基づく表記")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('a',{attrs:{"href":"https://www.millioneyes.jp/rule/"}},[_vm._v("ご利用規約")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('a',{attrs:{"href":"https://www.millioneyes.jp/company/"}},[_vm._v("会社概要")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('a',{attrs:{"href":"https://www.millioneyes.jp/sitemap_member/"}},[_vm._v("サイトマップ")])])
}]

export { render, staticRenderFns }