<template>
  <div class="layout_menu">
    <Header v-if="isUserSignedIn() && !isUserSleep() && !isUserWithdrawal()"/>
    <HeaderNoJoin v-else/>
    <Flash v-if="$store.state.flash_message"/>
    <div id="router-view-frame">
      <router-view/>
      <Footer v-if="isUserJoin() && !isUserSleep() && !isUserWithdrawal()"/>
      <FooterNoJoin v-else/>
    </div>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import Header from '@/elements/Header.vue'
import HeaderNoJoin from '@/elements/logout/Header.vue'
import Footer from '@/elements/Footer.vue'
import FooterNoJoin from '@/elements/logout/Footer.vue'
import Flash from '@/elements/Flash.vue'

export default {
  mixins: [Libraries],
  components: {
    Header, Footer, Flash,
    HeaderNoJoin, FooterNoJoin
  }
}
</script>
