<template>
  <!-- modal -->
  <div class="modal open">
    <div class="modal_box black">
      <div class="header">
        <h4>月謝の決済に失敗しました</h4>
      </div>
      <div class="inner draft cart">
        <div class="scroll_wrap">
          <div class="scroll_box" style="text-align: center">
            <template v-if="!bank_fee">
              <p class="bold mb10 red" >
                {{ moment(new Date(), 'YYYY年M月') }}の月謝の決済に失敗しました。<br>
                考えられる原因は限度額オーバーやカードの停止などです。<br>
                原因についてクレジットカード会社にご確認の上、<br class="pc">ご対応をお願いいたします。
              </p>
              <p class="bold size14">
                10日後前後に再決済をいたします。その際に決済が成功した場合は、<br class="pc">こちらのアラート画面は解消されます。<br class="pc">
                現在参加申し込み済みのイベントについても<br class="pc">{{ moment(new Date(), 'M月') }}10日までに月謝のお支払いが行われない場合は<br class="pc">キャンセル扱いとなり、参加することができなくなります。<br>
                この件についてご不明な点がある場合は<br class="pc">複眼経済塾事務局へ<a href="javascript:void(0);" v-on:click="onContactsClicked()">お問い合わせ</a>ください
              </p>
            </template>
            <template v-else>
              <p class="bold mb10 red">
                {{ moment(new Date(), 'YYYY年MM月') }}の月謝のお振込みが確認できておりません。<br>
                お支払いをお願いいたします。
              </p>
              <p class="bold size14">
                現在参加申し込み済みのイベントについても<br class="pc">{{ moment(new Date(), 'YYYY年M月') }}10日までに月謝のお支払いが行われない場合は<br class="pc">キャンセル扱いとなり、参加することができなくなります。<br>
                この件についてご不明な点がある場合は<br class="pc">複眼経済塾事務局へ<a href="javascript:void(0);" v-on:click="onContactsClicked()">お問い合わせ</a>ください
              </p>
            </template>
            <div class="button_wrap mt20">
              <a href="javascript:void(0);" class="submit black" v-on:click="$emit('cancel')">閉じる</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ovr ovr-dark"></div>
  </div>
  <!-- modal -->
</template>

<script>
import Libraries from "@/components/Libraries.vue";

export default {
  mixins: [Libraries],
  props: {
    bank_fee: null
  },
  methods: {
    onContactsClicked() {
      this.$router.push({ name: 'Contacts'})
      this.$emit('cancel')
    }
  }
}
</script>