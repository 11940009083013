import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueScrollTo from 'vue-scrollto'
import InfiniteLoading from 'vue-infinite-loading'

Vue.config.productionTip = false
Vue.use(InfiniteLoading)

// layouts
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import LoginLayout from '@/layouts/LoginLayout.vue'
import ConfirmationLayout from '@/layouts/ConfirmationLayout.vue'
import RegistrationLayout from '@/layouts/RegistrationLayout.vue'
import LpLayout from '@/layouts/LpLayout.vue'
import MaintenanceLayout from '@/layouts/MaintenanceLayout.vue'
import VueCookie from 'vue-cookie'

Vue.component('default-layout', DefaultLayout);
Vue.component('login-layout', LoginLayout);
Vue.component('confirmation-layout', ConfirmationLayout);
Vue.component('registration-layout', RegistrationLayout);
Vue.component('lp-layout', LpLayout);
Vue.component('maintenance-layout', MaintenanceLayout);

require('@/assets/sass/main.scss')

// set csrf token to axios
axios.interceptors.request.use((config) => {
    // for auth
    const current_user = JSON.parse(localStorage.getItem('user'))
    if (current_user && !no_auth) {
        config.headers['Authorization'] = `Bearer ${current_user.api_token}`
    }
    // for session
    const million_id = localStorage.getItem('million_id')
    if (million_id && !no_auth) {
        config.headers['X-MILLION-ID'] = million_id
    }
    return config
}, (error) => {
    return Promise.reject(error)
})

Vue.use(VueAxios, axios)
Vue.use(Vuex)
Vue.use(VueScrollTo)
Vue.use(VueCookie)
Vue.config.productionTip = false

let million_info = {}
let million_info_expire = 0

async function getMillionInfo() {
    if (million_info_expire < new Date().getTime()) {
        await axios.get(`${process.env.VUE_APP_API_BASE_URL}million_info.json`, {})
            .then((response) => {
                million_info = response.data
                if (million_info.user) {
                    const current_user = JSON.parse(localStorage.getItem('user'))
                    if (current_user) {
                        for (const key in million_info.user){
                            current_user[key] = million_info.user[key]
                        }
                        localStorage.setItem('user', JSON.stringify(current_user))
                        if (current_user.notice_fee_error) {
                            million_info_expire = 0
                        } else {
                            million_info_expire = new Date().getTime() + ((60 * 60) * 1000)
                        }
                    }
                } else {
                    million_info_expire = new Date().getTime() + ((60 * 60) * 1000)
                }
                // get/store million_id (session id)
                const million_id = response.headers['x-million-id']
                if (million_id) {
                    localStorage.setItem('million_id', million_id)
                }
            })
    }
    return million_info
}

router.beforeEach(async (to, from, next) => {
    if (process.env.VUE_APP_MAINTENANCE && !localStorage.getItem('maintenance_test') && to.name !== 'Maintenance') {
        next({name: 'Maintenance', query: {redirect: to.fullPath}});
        return
    }

    if (to.name !== 'Maintenance') {
        to.meta.million_info = await getMillionInfo();
        if (to.meta.million_info.front_hash && to.meta.million_info.front_hash !== localStorage.getItem('front_hash')) {
            console.warn("front hash changed. force reload", to.meta.million_info.front_hash);
            localStorage.setItem('front_hash', to.meta.million_info.front_hash)
            window.location.assign(to.fullPath);
            return;
        }
    }

    // jqueryの弊害対処
    window.$('nav').removeClass('open');
    window.$('#ovr').css({'top': 0, 'display': 'none'})
    // ログイン中じゃないと表示出来ないページかの門番
    const current_user = JSON.parse(localStorage.getItem('user'))
    // 月謝決済エラーのクラスを追加
    if (current_user && current_user.notice_fee_error) {
        document.body.classList.add('fee-failed')
    } else {
        document.body.classList.remove('fee-failed')
    }

    // セッションタイムアウト処理
    if (process.env.VUE_APP_SESSION_TIMEOUT && current_user && (to.meta.authenticate_user || to.meta.authenticate_user_join || to.meta.authenticate_user_no_join)) {
        // 会員ページ
        const session_date = localStorage.getItem('session-time') ? new Date(localStorage.getItem('session-time')) : null
        const now = new Date()
        if (session_date) {
            const diff = (now.getTime() - session_date.getTime()) / 1000
            if (diff >= Number(process.env.VUE_APP_SESSION_TIMEOUT)) {
                // console.log('session timeout', diff)
                next({name: 'UsersSignOut'});
                return
            }
        }
        localStorage.setItem('session-time', now.toString())
    }

    to.meta.current_user = current_user
    if (to.meta.authenticate_user) {
        // 登録済み入塾前塾生でも閲覧可能なページ
        if (!to.meta.current_user) {
            next({name: 'UsersSignIn', query: {redirect: to.fullPath}});
        } else if (!to.meta.current_user.paid_join &&
            to.name !== 'UsersCustomerCardUpdateFinish' && to.name !== 'UsersCustomerEmptyPaidJoin') {
            // まだ入塾金の決済を確認出来なければ、入塾金確認ページへ
            next({name: 'UsersCustomerEmptyPaidJoin'});
        } else if (to.meta.current_user.sleep && to.meta.authenticate_user_sleep !== true) {
            // 休塾中なら制限あり
            next({name: 'UsersCustomerAccount'});
        } else {
            next();
        }
    } else if (to.meta.authenticate_user_join) {
        // 正式な塾生なら閲覧可能なページ
        if (!to.meta.current_user) {
            next({name: 'UsersSignIn', query: {redirect: to.fullPath}});
        } else if (!to.meta.current_user.is_join || to.meta.current_user.sleep || to.meta.current_user.withdrawal) {
            next({name: 'UsersCustomerAccount'});
        } else {
            next();
        }
    } else if (to.meta.authenticate_user_no_join) {
        // 入塾前塾生なら閲覧可能なページ
        if (!to.meta.current_user) {
            next({name: 'UsersSignIn', query: {redirect: to.fullPath}});
        } else if (to.meta.current_user.is_join) {
            next({name: 'UsersCustomerAccount'});
        } else {
            next();
        }
    } else if (to.meta.authenticate_admin_user) {
        // 管理者ページ
        if (!to.meta.current_user) {
            next({name: 'UsersSignIn', query: {redirect: to.fullPath}});
        } else if (to.meta.current_user.rank !== 'rank_staff' && to.meta.current_user.status !== 'status_staff') {
            next({name: 'UsersCustomerAccount'});
        } else {
            next();
        }
    } else {
        next();
    }
});

const store = new Vuex.Store({state: {}})

router.afterEach(() => {
    window.$('body').removeClass('fixed').css({'top': 0});
    //  gaのページトラッキング
    // console.log(`gtag('config', '${process.env.VUE_APP_UATAG_ID}', {page_path: '${location.pathname}')`)
    gtag('config', process.env.VUE_APP_GTAG_ID, {page_path: location.pathname})
    // gtag('config', process.env.VUE_APP_UATAG_ID, {page_path: location.pathname})
    // gtag('config', 'G-9YP6W09HFT', {page_path: location.pathname})
    gtag('config', 'UA-65417881-1', {page_path: location.pathname})

    if (location.pathname === '/sns/Search') {
        document.body.classList.add('page_search')
    } else {
        document.body.classList.remove('page_search')
    }
})

new Vue({
    router, store, render: h => h(App),

}).$mount('#app')
