<template>
  <footer class="menu_none">
    <div class="footer_nav">
      <ul class="f_nav flex">
        <li><a href="/">複眼経済塾 トップ</a></li>
        <li v-if="false"><a href="">事務局blog</a></li>
        <li v-if="false"><a href="">塾生クラス変更</a></li>
        <li><a href="https://www.millioneyes.jp/privacy-policy/">個人情報保護方針</a></li>
        <li><a href="https://www.millioneyes.jp/transactions-law/">「特定商取引に関する<br class="sp">法律」に基づく表記</a></li>
        <li><a href="https://www.millioneyes.jp/rule/">ご利用規約</a></li>
        <li><a href="https://www.millioneyes.jp/company/">会社概要</a></li>
        <li v-if="isUserJoin()"><a href="https://www.millioneyes.jp/sitemap_member/">サイトマップ</a></li>
        <li v-else><a href="https://www.millioneyes.jp/sitemap/">サイトマップ</a></li>
      </ul>
    </div>
    <p id="copyright">&copy; {{ new Date().getFullYear() }} 複眼経済塾株式会社 All Rights Reserved.</p>
  </footer>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries]
}
</script>