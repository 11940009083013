<template>
  <div class="top_fixed">
    <header class="menu_lp">
      <div class="wrap flex">
        <button class="sp_menu_btn"><span></span><span></span><span></span>Open Menu</button>
        <!--スマホメニュー開閉ボタン オープン時はnavにclass open-->
        <h1 id="h_logo">
          <a href="/">
            <div class="logo">複眼経済塾</div>
          </a>
        </h1>
        <ul class="h_nav flex">
          <li class="member_info">
            <div v-html="header_lead_text"></div>
          </li>
          <!--<li class="pc_menu"><a href="">よくあるご質問</a></li>
          <li class="pc_menu"><a href="">お問い合わせ</a></li>
          <li class="pc_menu"><a href="javascript:void(0)" class="btn search"><span>サイト内検索</span></a></li>
          <li class="pc_menu"><a href="" class="btn my_timeline"><span>自分のタイムライン</span></a></li>
          <li class="sp_menu"><a href="" class="alert on"><img src="../common/images/icon/bell.svg" alt="通知"></a></li>
          <li><a href="" class="btn user login"><i class="user_icon"><img src="../common/images/sample/user_thumb.png" alt=""></i><span></span></a></li>
          <li class="pc_menu"><a href="" class="alert on"><img src="../common/images/icon/bell.svg" alt="通知"></a></li>-->
        </ul>
      </div>
    </header>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries]
}
</script>
