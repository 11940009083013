<template>
  <div class="search_menu open">
    <div class="s_wrap">
      <form @submit="update" id="form">
        <h3 class="min_ttl sp">サイト内を検索する</h3>
        <div class="free_search_box">
          <input type="search" class="free_search" placeholder="検索したい内容を入力" v-model="query" ref="searchInput">
        </div>
        <div class="search_box">
          <h3 class="bold" v-if="false">検索内容の絞り込み</h3>
          <p v-if="false">絞り込みたい検索項目をチェックしてください。（複数可）</p>
          <div class="cat" v-if="false">
            <div class="alert_search">
              <div class="checkbox c_red">
                <input id="check01" type="checkbox" v-model="filters" value="monthly">
                <label for="check01">月例会</label>
              </div>
              <div class="checkbox c_red">
                <input id="check02" type="checkbox" v-model="filters" value="weekly">
                <label for="check02">ウィークリー更新講義</label>
              </div>
              <div class="checkbox c_red">
                <input id="check03" type="checkbox" v-model="filters" value="random">
                <label for="check03">ランダム更新講義</label>
              </div>
              <div class="checkbox c_red">
                <input id="check_basic" type="checkbox" v-model="filters" value="basic">
                <label for="check_basic">基本必修講義</label>
              </div>
              <div class="checkbox c_red">
                <input id="check04" type="checkbox" v-model="filters" value="notice">
                <label for="check04">事務局通信</label>
              </div>
              <div class="checkbox c_red">
                <input id="check05" type="checkbox" v-model="filters" value="jukucho">
                <label for="check05">渡部清二塾長通信</label>
              </div>
              <div class="checkbox c_red">
                <input id="check20" type="checkbox" v-model="filters" value="you_think">
                <label for="check20">What do you think？</label>
              </div>
              <div class="checkbox c_red">
                <input id="check21" type="checkbox" v-model="filters" value="emin_twitter">
                <label for="check21">Markets</label>
              </div>
              <div class="checkbox c_red">
                <input id="check22" type="checkbox" v-model="filters" value="contest">
                <label for="check22">アツアツ銘柄コンテスト</label>
              </div>
              <div class="checkbox c_red">
                <input id="check07" type="checkbox" v-model="filters" value="contents">
                <label for="check07">講義</label>
              </div>
              <div class="checkbox c_red">
                <input id="check23" type="checkbox" v-model="filters" value="social_kabu">
                <label for="check23">株主総会報告</label>
              </div>
              <div class="checkbox c_red" v-if="false">
                <input id="check08" type="checkbox" v-model="filters" value="workshop">
                <label for="check08">研修会</label>
              </div>
              <div class="checkbox c_red">
                <input id="check09" type="checkbox" v-model="filters" value="social">
                <label for="check09">テーマ別グループ</label>
              </div>
              <div class="checkbox c_red">
                <input id="check18" type="checkbox" v-model="filters" value="timeline">
                <label for="check18">複眼SNS</label>
              </div>
              <div class="checkbox c_red">
                <input id="check19" type="checkbox" v-model="filters" value="media">
                <label for="check19">メディア</label>
              </div>
              <div class="checkbox c_red" v-if="false">
                <input id="check10" type="checkbox">
                <label for="check10">外部リンク</label>
              </div>
              <div class="checkbox c_red">
                <input id="check11" type="checkbox" v-model="filters" value="movie">
                <label for="check11">動画データ</label>
              </div>
              <div class="checkbox c_red">
                <input id="check12" type="checkbox" v-model="filters" value="voice">
                <label for="check12">音声データ</label>
              </div>
              <div class="checkbox c_red">
                <input id="check13" type="checkbox" v-model="filters" value="pdf">
                <label for="check13">PDFレポート</label>
              </div>
            </div>
          </div>
          <div class="cat" v-if="false">
            <p>人気のハッシュタグで絞り込む（複数可）</p>
            <div class="alert_search">
              <div class="checkbox c_red">
                <input id="check14" type="checkbox">
                <label for="check14"><span class="s_tag">タグ名</span></label>
              </div>
              <div class="checkbox c_red">
                <input id="check15" type="checkbox">
                <label for="check15"><span class="s_tag">タグ名</span></label>
              </div>
              <div class="checkbox c_red">
                <input id="check16" type="checkbox">
                <label for="check16"><span class="s_tag">タグ名</span></label>
              </div>
              <div class="checkbox c_red">
                <input id="check17" type="checkbox">
                <label for="check17"><span class="s_tag">タグ名</span></label>
              </div>
            </div>
          </div>
          <div class="alnC" v-if="false">
            <a href="javascript:void(0);" class="all_check" @click="filters = []">すべてのチェックをはずす</a>
          </div>
          <div class="button_wrap">
            <input type="submit" class="submit light skeleton" value="検索する">
          </div>

          <div class="menu_close" v-on:click="$emit('closed')">
            <span><img src="/common/images/icon/megamenu_close.svg" alt="閉じる"></span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return this.initialize()
  },
  watch: {
    $route(/*to, from*/) {
      this.reset()
    }
  },
  methods: {
    initialize() {
      const _filters = this.$route.query.filters == null ? '' : this.$route.query.filters
      const filters = _filters.length === 0 ? [] : _filters.split(',')
      return {
        filters: filters,
        query: this.$route.query.query
      }
    },
    reset() {
      Object.assign(this.$data, this.initialize());
    },

    update(event) {
      event.preventDefault()
      this.$emit('closed')
      let query = {}
      query.query = this.query
      // const filters = this.filters.join(',')
      // query.filters = filters

      this.$router.push({
        name: 'SnsSearch',
        query: query
      })
    }
  },
  mounted() {
    this.$refs.searchInput.focus()
  }
}
</script>
