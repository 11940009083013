<template>
  <div id="app">
    <component v-bind:is="layout"/>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  computed: {
    layout() {
      return (this.$route.meta.layout || 'default') + '-layout';
    }
  },
  mounted() {
    this.setMeta(this.$route)
  },
  watch: {
    $route(to, /* from */) {
      this.setMeta(to.meta)
    }
  },
}
</script>
