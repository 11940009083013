<template>
  <div v-if="false">
    {{ flash_message }}
  </div>
</template>

<script>
export default {
  data() {
    const flash_message = this.$store.state.flash_message
    this.$store.state.flash_message = null
    return {
      flash_message: flash_message
    }
  }
}
</script>
