<script>
import Moment from 'moment'
import {KJUR} from 'jsrsasign'
import AxiosJsonpAdapter from 'axios-jsonp'

export default {
  data() {
    return {
      env: {
        api_base_url: process.env.VUE_APP_API_BASE_URL,
      },
      current_user: this.$route.meta.current_user,
      header_lead_text: this.$route.meta.million_info && this.$route.meta.million_info.header_lead_text,
      dan_array: ['rank00', 'rank05', 'rank04', 'rank03', 'rank02', 'rank01', 'rank1', 'rank2', 'rank3', 'rank4', 'rank5'],
      paygent_status_polling_timer: null,
      paygent_status: null,
    }
  },

  destroyed() {
    if (this.paygent_status_polling_timer) {
      clearTimeout(this.paygent_status_polling_timer)
      this.paygent_status_polling_timer = null
    }
  },

  methods: {
    isProduction() {
      return process.env.NODE_ENV === 'production'
    },
    isDevelopment() {
      return process.env.NODE_ENV === 'development'
    },
    // make JWT
    makeJwtToken(email, password) {
      const header = {alg: 'HS256', typ: 'JWT'}
      const payload = {sub: email, iss: password}
      const API_SECRET_KEY_BASE = 'secretToken'
      // console.log(header, payload)
      return KJUR.jws.JWS.sign('HS256', JSON.stringify(header), JSON.stringify(payload), API_SECRET_KEY_BASE)
    },
    // set flash message
    setFlash(message) {
      this.$store.state.flash_message = message
    },
    // save user session
    saveSession(user) {
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('session-time', new Date().toString())
      this.$route.meta.current_user = user
      this.current_user = user
    },
    // remove user session
    removeSession() {
      localStorage.removeItem('user')
      localStorage.removeItem('session-time')
      this.$route.meta.current_user = null
      this.current_user = null
    },
    // check signed in ?
    isUserSignedIn() {
      return this.$route.meta.current_user !== null
    },

    // 会員は登録後のユーザか
    isUserJoin() {
      return this.$route.meta.current_user && this.$route.meta.current_user.is_join
    },

    // 会員は休塾中のユーザか
    isUserSleep() {
      return this.$route.meta.current_user && this.$route.meta.current_user.sleep
    },

    // 会員は退塾中のユーザか
    isUserWithdrawal() {
      return this.$route.meta.current_user && this.$route.meta.current_user.withdrawal
    },

    // 会員は退塾申請中のユーザか
    isUserWithdrawalRequest() {
      return this.$route.meta.current_user && this.$route.meta.current_user.withdrawal_request
    },

    // 管理者か
    isStaff() {
      return this.$route.meta.current_user && (this.$route.meta.current_user.rank === 'rank_staff' || this.$route.meta.current_user.status === 'status_staff')
    },

    // 旧塾生か
    isRegularDefault() {
      return this.$route.meta.current_user && (
          this.$route.meta.current_user.rank === 'rank_regular_default_shikiho_premium_month' ||
          this.$route.meta.current_user.rank === 'rank_regular_default_shikiho_basic_month' ||
          this.$route.meta.current_user.rank === 'rank_default')
    },

    // 四季報ありか
    isShikiho() {
      return this.$route.meta.current_user && (
          this.$route.meta.current_user.rank === 'rank_regular_default_shikiho_premium_month' ||
          this.$route.meta.current_user.rank === 'rank_regular_default_shikiho_basic_month' ||
          this.$route.meta.current_user.rank === 'rank_regular_plus_year' ||
          this.$route.meta.current_user.rank === 'rank_regular_plus_month' ||
          this.$route.meta.current_user.rank === 'rank_regular_shikiho_premium_year' ||
          this.$route.meta.current_user.rank === 'rank_regular_shikiho_basic_year')
    },

    // 四季報premiumか
    isShikihoPremium () {
      return this.$route.meta.current_user && (
          this.$route.meta.current_user.rank === 'rank_regular_default_shikiho_premium_month' ||
          this.$route.meta.current_user.rank === 'rank_regular_plus_year' ||
          this.$route.meta.current_user.rank === 'rank_regular_plus_month' ||
          this.$route.meta.current_user.rank === 'rank_regular_shikiho_premium_year')
    },

    // 四季報premiumか
    isShikihoBasic () {
      return this.$route.meta.current_user && (
          this.$route.meta.current_user.rank === 'rank_regular_default_shikiho_basic_month' ||
          this.$route.meta.current_user.rank === 'rank_regular_shikiho_basic_year')
    },

    // 塾生新規登録中か
    isRegistrationEnabled() {
      if (this.$route.meta.million_info.reg_start_date && this.$route.meta.million_info.reg_end_date) {
        const now = new Date()
        return new Date(this.$route.meta.million_info.reg_start_date) <= now && new Date(this.$route.meta.million_info.reg_end_date) >= now
      } else {
        return process.env.VUE_APP_REGISTRATION_ENABLED === 'true'
      }
    },

    // 塾生新規登録中のテスト中か
    isRegistrationDebugEnabled() {
      return process.env.VUE_APP_REGISTRATION_DEBUG_ENABLED === 'true'
    },

    // ga4 event 発行
    ga4Event(data) {
      // console.log('ga4Event', data)
      gtag('config', process.env.VUE_APP_GTAG_ID, data);
    },

    // 日付のフォーマット
    moment(date, format) {
      Moment.locale("ja");
      return Moment(date).format(format)
    },

    // 3桁区切り
    delimited(number) {
      number = parseInt(number)
      return isNaN(number) ? '' : parseInt(number).toLocaleString()
    },

    // 画面の先頭へ移動
    scrollTop() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      //window.scrollTo({
      //  top: 0,
      //  behavior: 'auto' //"smooth"
      //})
    },

    // ankerへ移動
    scrollTo(anker, duration = 0, option = {}) {
      this.$scrollTo(anker, duration, option)
    },

    // 郵便番号検索
    addressSearch(zip) {
      return new Promise((resolve, reject) => {
        this.axios.get(`https://api.zipaddress.net/?zipcode=${zip}`, {adapter: AxiosJsonpAdapter}).then(rs => {
          if (!rs.data.code) {
            resolve(rs.data)
          } else {
            reject(rs.data)
          }
        })
      })
    },

    // 現在の入塾の日付を返す
    getMembershipJoinDate(dateStr) {
      const date = new Date(dateStr)
      return new Date(date.getFullYear(), date.getMonth() + 1)
    },

    // meta情報を設定
    setMeta(meta) {
      let title = process.env.VUE_APP_DEFAULT_TITLE
      let description = process.env.VUE_APP_DEFAULT_DESCRIPTION
      if (meta.title) {
        if (this.$route.path === '/' || this.$route.path === '/lp' || this.$route.path === '/lp/') {
          title = process.env.VUE_APP_DEFAULT_TITLE_PREFIX.substring(1)
        } else {
          title = meta.title + process.env.VUE_APP_DEFAULT_TITLE_PREFIX
        }
      }
      if (meta.description) {
        description = meta.description
      }
      //document.querySelector("meta[property='og:title']").setAttribute('content', title)
      document.title = title
      document.querySelector("meta[property='og:url']").setAttribute('content', 'https://new.millioneyes.jp' + this.$route.path)
      document.querySelector("meta[name='description']").setAttribute('content', description)
      document.querySelector("meta[property='og:description']").setAttribute('content', description)
      //document.querySelector("meta[name='twitter:title']").setAttribute('content', title)
      //document.querySelector("meta[name='twitter:description']").setAttribute('content', description)

      const login = !!(meta.authenticate_user || meta.authenticate_user_join || meta.authenticate_user_no_join)
      const robots = login ? 'noindex, nofollow' : 'index, follow'
      document.querySelector("meta[name='robots']").setAttribute('content', robots)
    },

    // CacheStore
    loadCacheStore(key) {
      if (!this.$store.state.cache_store) {
        this.$store.state.cache_store = {}
        return null;
      }
      return this.$store.state.cache_store[key]
    },
    saveCacheStore(key, contents) {
      if (!this.$store.state.cache_store) {
        this.$store.state.cache_store = {}
      }
      this.$store.state.cache_store[key] = contents
    },

    addClassByElement(element, class_name) {
      if (element) {
        let class_names = element.className.split(' ')
        if (class_names.indexOf(class_name) === -1) {
          class_names.push(class_name)
          element.className = class_names.join(' ')
        }
      }
    },

    addClassByElementId(element_id, class_name) {
      const element = document.getElementById(element_id)
      if (element) {
        this.addClassByElement(element, class_name);
      } else {
        setTimeout(() => {
          this.addClassByElement(document.getElementById(element_id), class_name);
        }, 0)
      }
    },
    removeClassByElementId(element_id, class_name) {
      const element = document.getElementById(element_id)
      if (element) {
        //element.className = ''
        let class_names = element.className.split(' ')
        const index = class_names.indexOf(class_name)
        if (index !== -1) {
          class_names.splice(index, 1);
          element.className = class_names.join(' ')
        }
      }
    },
    // ajaxの開始・終了・エラー
    startAjax() {
      this.addClassByElementId('router-view-frame', 'loading_ajax')
    },
    finishAjax() {
      //this.$forceUpdate();
      //this.removeClassByElementId('router-view-frame', 'loading_ajax')
      window.setTimeout(() => {
        this.removeClassByElementId('router-view-frame', 'loading_ajax')
      }, 100)

      // 元の位置に戻ってみる
      const positionY = sessionStorage.getItem('positionY')
      sessionStorage.removeItem('positionY')
      if (positionY) {
        scrollTo(0, positionY);
        setTimeout(function () {
          scrollTo(0, positionY);
        }, 100);
      }
    },
    errorAjax(error) {
      this.finishAjax()
      if (error.response.status === 422 || error.response.status === 401) {
        // ログインへ
        this.removeSession()
        this.$router.push({name: 'UsersSignIn', query: {redirect: this.$route.fullPath}})
        return
      }
      if (this.isDevelopment()) {
        console.log(error)
        alert(error.response.status)
        alert(JSON.stringify(error.response.data))
      }

      if (error.response.status === 404) {
        this.$router.push({name: '404'})
      } else if (error.response.status === 403) {
        this.$router.push({name: '403'})
      } else {  // 500エラーページへ
        this.$router.push({name: '500'})
      }
    },

    // ファイルアップロードの開始・終了
    startUpload() {
      this.addClassByElementId('body', 'loading')
    },
    finishUpload() {
      this.removeClassByElementId('body', 'loading')
    },

    // accordion menu
    accordion(id, is_open) {
      const element = window.$('#' + id)
      const height = element.height()
      if (!is_open) {
        element.animate({
          'height': '0'
        }, {
          duration: 200, easing: 'swing',
          complete: () => {
            element.css('display', 'none')
            element.css('height', height)
          }
        });
      } else {
        element.css('height', '0px')
        element.css('display', 'block')
        element.css('overflow', 'hidden')
        element.animate({
          'height': height + 'px'
        }, {
          duration: 200, easing: 'swing',
          complete: () => {
          }
        });
      }
    },

    // 半角→全角(英数字)
    replaceHalfToFull(str) {
      if (!str) return str
      str = str.replace(/[!-~]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
      });
      return this.replaceKanaHalfToFull(str)
    },

    // ひらがな→カタカナ
    replaceHiraToKana(str) {
      if (!str) return str
      str = this.replaceHalfToFull(str)
      return str.replace(/[\u3041-\u3096]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) + 0x60);
      });
    },

    // 半角→全角(カタカナ)
    replaceKanaHalfToFull(str) {
      if (!str) return str
      const kanaMap = {
        'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
        'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
        'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
        'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
        'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
        'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
        'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
        'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
        'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
        'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
        'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
        'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
        'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
        'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
        'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
        'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
        'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
        'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
        '｡': '。', '､': '、', 'ｰ': 'ー', '｢': '「', '｣': '」', '･': '・'
      };
      let reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
      return str.replace(reg, function (s) {
        return kanaMap[s];
      }).replace(/ﾞ/g, '゛').replace(/ﾟ/g, '゜');
    },

    // 全角→半角(英数字)
    replaceFullToHalf(str) {
      if (!str) return str

      str = str.replace(/[！-～]/g,
          function (tmpStr) {
            return String.fromCharCode(tmpStr.charCodeAt(0) - 0xFEE0)
          }
      );
      str = str.replace(/[ー―−]/g, "-")
      return str.replace(/[^ -~｡-ﾟ]+/g, '');
    },

    // 全角→半角数字
    replaceFullToNumber(str) {
      if (!str) return str

      return str.replace(/[０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 65248)).replace(/\D/g, '')
    },

    // 月の差分を計算
    diffMonth(d1, d2) {
      const d1Month = d1.getFullYear() * 12 + d1.getMonth();
      const d2Month = d2.getFullYear() * 12 + d2.getMonth();
      return d2Month - d1Month;
    },

    // 学生科の初回月謝（日割り）を返す
    studentFirstPrice() {
      const date = new Date()
      const lastDate = new Date(date.getFullYear(), date.getMonth()+1, 0)
      const days = lastDate.getDate()
      return Math.ceil((2000.0 / days) * (days - date.getDate() + 1))
    },

    // SNS MEDIAのIDを返す
    sns_media_id() {
      return process.env.VUE_APP_SNS_MEDIA_ID || 28
    },

    // 段位名を返す
    achievementLabel(dan) {
      return {
        rank_staff: '5級挑戦中',
        rank00: '5級挑戦中',
        rank05: '5級',
        rank04: '4級',
        rank03: '3級',
        rank02: '2級',
        rank01: '1級',
        rank1: '初段',
        rank2: '二段',
        rank3: '三段',
        rank4: '四段',
        rank5: '五段'
      }[dan]
    },

    // 次の段位名を返す
    nextAchievementLabel(dan) {
      const now_index = this.dan_array.indexOf(dan)
      return this.achievementLabel(this.dan_array[now_index + 1])
    },

    // 段位はクリアしているか
    isNoClearAchievement(dan, check_dan) {
      const now_index = this.dan_array.indexOf(dan)
      const check_index = this.dan_array.indexOf(check_dan)
      return (now_index + 1 <= check_index)
    },

    // 2重送信防止トークン
    setFormToken(token) {
      if (!this.$store.state.form_token) this.$store.state.form_token = {}
      this.$store.state.form_token[token] = new Date()
    },
    removeFormToken(token) {
      if (!this.$store.state.form_token) this.$store.state.form_token = {}
      this.$store.state.form_token[token] = null
    },
    checkFormToken(token, router_name = 'UsersCustomerAccount') {
      if (!this.$store.state.form_token) this.$store.state.form_token = {}
      if (!this.$store.state.form_token[token]) {
        this.$router.push({name: router_name})
        return false
      }
      return true
    },

    // paygent決済結果の同期処理
    startPaygentStatusPolling(callbacks) {
      if (this.$route.query.debug === '1') {
        callbacks('status_waiting')
      } else if (this.$route.query.debug === '2') {
        callbacks('status_failed')
      } else if (this.$route.query.trading_id) {
        this.axios
            .get(`${this.env.api_base_url}users/paygent_status.json`, {
              params: {trading_id: this.$route.query.trading_id}
            })
            .then(response => {
              this.paygent_status = response.data.status
              callbacks(this.paygent_status)
            })
            .catch((response) => {
              this.errorAjax(response)
            })
            .finally(() => {
              if (this.paygent_status === 'status_waiting') {
                this.paygent_status_polling_timer = setTimeout(() => {
                  this.paygent_status_polling_timer = null
                  this.startPaygentStatusPolling(callbacks)
                }, 3000)
              }
            })
      }
    },
    // タイムラインのURL
    myTimelineUrl() {
      const timeline_uri = this.$router.resolve({
        name: 'SnsTimeline',
        params: { user_id: this.current_user.id, command: 'follow' }
      }).href
      return document.location.origin + timeline_uri
    },

    htmlEscape(str) {
      return str
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
    },
    // 検索文字列に Mark
    queryMarker(body, query) {
      if (query) {
        body = body.replaceAll(query, '<span class="query_marker">' + query + '</span>');
      }
      return body
    }
  }
}
</script>
